
<!-- End User profile text-->
<li class="nav-devider"></li>
<!-- Sidebar navigation-->
<nav class="sidebar-nav">
    <ul id="sidebarnav">
        <!-- First level menu -->
        <li [ngClass]="{'active': showMenu === sidebarnavItem.title}" *ngFor="let sidebarnavItem of sidebarnavItems">
            <div class="nav-small-cap" *ngIf="sidebarnavItem.extralink === true  && sidebarnavItem.isActiveFor.indexOf(authService.getUserRole()) !== -1">{{sidebarnavItem.title}}</div>
            <!-- [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null" -->
            <a   routerLink="/{{sidebarnavItem?.path}}" routerLinkActive="active"  [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink && sidebarnavItem.isActiveFor.indexOf(authService.getUserRole()) !== -1"
                (click)="addExpandClass(sidebarnavItem.title)" style="text-decoration: none;">
                <i [ngClass]="[sidebarnavItem.icon]"></i>
                <span class="hide-menu">{{sidebarnavItem.title}}
                    <span *ngIf="sidebarnavItem.label != '' " [ngClass]="[sidebarnavItem.labelClass]">{{sidebarnavItem.label}}</span>
                </span>
            </a>
        </li>
    </ul>
</nav>
