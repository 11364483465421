import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../config/environment';
const HttpUploadOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
};

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private baseUrl = environment.baseUrl;
    loaderStatus: any;
    subcategoryid: any;

    constructor(
        private httpRequest: HttpClient,
        private router: Router
    ) { }

    //Common function for routing to dashboard
    userRedirectToDashboard = (userRole) => {
            if (userRole && (userRole === 'super_admin' || userRole === 'staff')) {
                this.router.navigate(['dashboard/admin']);
            }
    }

    //Set - get loader
    setLoader = (status) => {
        this.loaderStatus = status;
    }

    getLoader = () => {
        return this.loaderStatus;
    }

    //User detail and update services
    getUserDetails = () => {
        return this.httpRequest.get(this.baseUrl + 'user');
    }

    updateUserDetails = (userDetail) => {
        return this.httpRequest.patch(this.baseUrl + 'user', userDetail);
    }
    
    updateUserProfile = (image) => {
        return this.httpRequest.patch(this.baseUrl + 'user', image);
    }

    


    // userSignup = (userDetail) => {
    //     delete userDetail['terms'];
    //     if (userDetail.role_names !== 'admin' && userDetail.role_names !== 'super_admin') {
    //         delete userDetail['school_name'];
    //     }
    //     return this.httpRequest.post(this.baseUrl + 'users/registration', { user: userDetail });
    // }
}
