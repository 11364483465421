import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../config/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private baseUrl = environment.baseUrl;

  constructor(private httpRequest: HttpClient) {
   }


  isLoggedIn(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      return true;
    } else {
      return false;
    }
  }
  getUserRole = () => {
    const userRole = localStorage.getItem('userRole');
     
    if (userRole) {
      return userRole;
    }
  }
  getUserId = () => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      return userId;
    }
  }

  getUserName = () => {
    const userName = localStorage.getItem('name');
    if (userName) {
      return userName;
    }
  }
  getAvatar = () => {
    const avatar = localStorage.getItem('avatar');
    if (avatar) {
      return avatar;
    }
  }
  getUserEmail = () => {
    const userEmail = localStorage.getItem('email');
    if (userEmail) {
      return userEmail;
    }
  }
  getCompanyDetails = () => {
      let companyData = localStorage.getItem('companyName');
      const city = localStorage.getItem('cityName');
      if(companyData && city){
        companyData = companyData+',' +city;
        return companyData
        }else if(companyData && !city){
          return companyData
      }
  }

  userLogin = (userCredentials: any) => {
    return this.httpRequest.post(this.baseUrl + 'oauth/token', userCredentials);
  }

}
