<header class="topbar">
    <nav class="d-flex top-navbar navbar-expand-md navbar-light">
        <!-- ============================================================== -->
        <!-- Logo -->
        <!-- ============================================================== -->
        <div class="d-flex sidebar-account flex-shrink-0 mr-auto mr-lg-0">
          <a href="index.html" class="flex d-flex align-items-center navbar-brand text-underline-0">
              <span class="mr-1  text-white">
                  <!-- LOGO -->
                   <img src="assets/images/logo-w.svg" alt="" style="max-height: 40px;
                   width: 100%;">
              </span>

          </a>
      </div>
        <!-- <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <b>

                    <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo" />
                    <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo" />
                </b>
                <span>
                    <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo" />
                    <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage" />
                </span>
            </a>
        </div> -->
        <!-- ============================================================== -->
        <!-- End Logo -->
        <!-- ============================================================== -->
        <div class="navbar-collapse" *ngIf="authService.isLoggedIn()">
            <!-- ============================================================== -->
            <!-- toggle and nav items -->
            <!-- ============================================================== -->
            <ul class="navbar-nav mr-auto mt-md-0">
               
                 <!-- This is  -->
                 <li (click)="toggleSidebar.emit()" class="nav-item m-l-10">
                    <a class="nav-link sidebartoggler waves-effect waves-dark" href="javascript:void(0)"><i
                            class="ti-menu"></i></a>
                </li>
                <li>
                    <a class="nav-link text-white waves-effect" href="javascript:void(0)">
                        <h3 style="top: 4px; margin: 0; position: relative;">{{companyData}}</h3>
                     </a> 
                </li>
            </ul>
            
            <!-- ============================================================== -->
            <!-- User profile and search -->
            <!-- ============================================================== -->

          



            <ul class="navbar-nav my-lg-0">
                <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle class="nav-link text-muted text-muted waves-effect waves-dark"
                        href="javascript:void(0)">
                        <i class="mdi mdi-message"></i>
                        <div class="notify">
                            <span class="heartbit"></span>
                            <span class="point"></span>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-left mailbox" ngbDropdownMenu>
                        <ul>
                            <li>
                                <div class="drop-title">Notifications</div>
                            </li>
                            <li>
                                <div class="message-center" [perfectScrollbar]="config">
                                    <!-- Message -->
                                    <a href="#" *ngFor="let notification of notifications">
                                        <div class="round {{notification.round}}">
                                            <i class="{{notification.icon}}"></i>
                                        </div>
                                        <div class="mail-contnet">
                                            <h5>{{notification.title}}</h5>
                                            <span class="mail-desc">{{notification.subject}}</span>
                                            <span class="time">{{notification.time}}</span>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a class="nav-link text-center" href="javascript:void(0);">
                                    <strong>Check all notifications</strong>
                                    <i class="fa fa-angle-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>

                <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark" href="javascript:void(0)">
                        <i class="mdi mdi-email"></i>
                        <div class="notify">
                            <span class="heartbit"></span>
                            <span class="point"></span>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right mailbox" ngbDropdownMenu>
                        <ul>
                            <li>
                                <div class="drop-title">You have 4 new messages</div>
                            </li>
                            <li>
                                <div class="message-center" [perfectScrollbar]="config">
                                    <a href="#" *ngFor="let mymessage of mymessages">
                                        <div class="user-img">
                                            <img src="{{mymessage.useravatar}}" alt="user" class="img-circle"
                                                width="40">
                                            <span class="profile-status {{mymessage.status}} pull-right"></span>
                                        </div>
                                        <div class="mail-contnet">
                                            <h5>{{mymessage.from}}</h5>
                                            <span class="mail-desc">{{mymessage.subject}}</span>
                                            <span class="time">{{mymessage.time}}</span>
                                        </div>
                                    </a>
                                </div>
                            </li>
                            <li>
                                <a class="nav-link text-center" href="javascript:void(0);">
                                    <strong>See all e-Mails</strong>
                                    <i class="fa fa-angle-right"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </li>
                <!-- ============================================================== -->
                <!-- Search -->
                <!-- ============================================================== -->

                <!-- ============================================================== -->
                <!-- Profile -->
                <!-- ============================================================== -->
                <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
                    <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark"
                        href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="{{authService.getAvatar()}}" onerror="this.src='../../../assets/images/dummy.png'" alt="user" class="profile-pic" />
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <ul class="dropdown-user">
                            <li>
                                <div class="dw-user-box">
                                    <div class="u-img">
                                        <img src="{{authService.getAvatar()}}" onerror="this.src='../../../assets/images/dummy.png'" alt="user">
                                    </div>
                                    <div class="u-text">
                                        <h4>{{authService.getUserName()}}</h4>
                                        <p class="text-muted">{{authService.getUserEmail()}}</p>
                                    </div>
                                </div>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li>
                                <a [routerLink]="['/login/profile']">
                                    <i class="ti-user"></i> My Profile</a>
                            </li>
                            <!-- <li>
                                <a href="#">
                                    <i class="ti-wallet"></i> My Balance</a>
                            </li> -->
                            <li>
                                <a href="#">
                                    <i class="ti-email"></i> Inbox</a>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li>
                                <a href="#">
                                    <i class="ti-settings"></i> Account Setting</a>
                            </li>
                            <li role="separator" class="divider"></li>
                            <li>
                                <a style="cursor: pointer" (click)="logout()">
                                    <i class="fa fa-power-off"></i> Logout</a>
                            </li>
                        </ul>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</header>
