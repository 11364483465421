import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    CommonModule,
    LocationStrategy,
    HashLocationStrategy
} from '@angular/common';
import { NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthInterceptor } from './interceptor/auth-interceptor';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { UsableUIComponent } from './usable-ui/usable-ui.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SettingComponent } from './setting/setting-details/setting.component';
import { SupportComponent } from './support/support-details/support.component';
import { MaterialComponentModule } from './shared/material-components.module';

import {CustomerGuard} from'./customer/customer-guard.guard';
import { DataTablesModule } from 'angular-datatables';
import { ProfileEditGuard } from './dashboards/dashboard-components/profile/profile-edit.guard';
import { ProfileAddGuard } from './dashboards/dashboard-components/profile/profile-add.guard';
import { AddCustomerGuard } from './customer/add-customer.guard';
import {A11yModule} from '@angular/cdk/a11y';
import { QuillModule } from 'ngx-quill';
import { AddMultipleRowComponent } from './add-multiple-row/add-multiple-row.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelSpeed: 2,
    wheelPropagation: true
};

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,
        FullComponent,
        BlankComponent,
        NavigationComponent,
        BreadcrumbComponent,
        SidebarComponent,
        UsableUIComponent,
        SettingComponent,
        SupportComponent,
        AddMultipleRowComponent

      ],
    imports: [
        CommonModule,
        BrowserModule,
        A11yModule,
        DataTablesModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        NgbModule,
        ToastrModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        QuillModule.forRoot(),
        PerfectScrollbarModule,
        AppRoutingModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        ImageCropperModule,
        RouterModule,
        MaterialComponentModule
    ],

    providers: [CustomerGuard, AddCustomerGuard, ProfileAddGuard, ProfileEditGuard,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
         },
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        } ],
    bootstrap: [AppComponent]
})
export class AppModule { }
