import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';
  ngOnInit(): void {
    if (localStorage.getItem('userRole')) {
      this.getUserDetails();
    }
  }

  constructor(
    private userService: UserService,
    public router: Router
    ) { }
  getUserDetails = () => {
    this.userService.getUserDetails().subscribe((projectDetail: any) => {
      localStorage.setItem('userRole', projectDetail['role']);
      localStorage.setItem('name', projectDetail['name']);
      localStorage.setItem('email', projectDetail['email']);
      localStorage.setItem('last_sign_in_at', projectDetail['last_sign_in_at']);
      localStorage.setItem('userId', projectDetail['id']);
      if(projectDetail['avatar'] && projectDetail['avatar'].asset_expiring_url){
          localStorage.setItem('avatar', projectDetail['avatar'].asset_expiring_url);
      }
      this.userService.userRedirectToDashboard(projectDetail['role']);
    },
    error => {
      if (error.status === 401) {
        localStorage.clear();
        this.router.navigateByUrl('/login');
    }
    });
  }
}
