 
<div> 
    <h5>Alert </h5>
    <hr>
    <div class="row"> 
        <div class="col-sm-3"> 
            <div class="card  bg-success ">
                <div class="card-body text-center p-4">
                    <span class="material-icons  ft-50 text-white mb-2">check</span>
                    <h4 class="text-white">Well Done!</h4>
                    <p class="text-white mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt eaque explicabo, itaque iusto unde quas magni non, quae expedita eveniet, incidunt molestiae tempora maiores odit hic perspiciatis. Eveniet, porro illo.</p>
                    <button type="button" class="btn btn-light my-2" data-dismiss="modal">Continue</button>
                </div> <!-- // END .modal-body -->
            </div> <!-- // END .modal-content -->
        </div>

        <div class="col-sm-3"> 
             <!-- Info Alert Modal -->
         <div class="card ">
            <div class="modal-body text-center p-4">
                <span class="material-icons   ft-50 text-info mb-2">info_outline</span>
                <h4>Heads Up!</h4>
                <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt eaque explicabo, itaque iusto unde quas magni non, quae expedita eveniet, incidunt molestiae tempora maiores odit hic perspiciatis. Eveniet, porro illo.</p>
                <button type="button" class="btn btn-info my-2" data-dismiss="modal">Continue</button>
            </div> <!-- // END .modal-body -->
        </div> <!-- // END .modal-content --> 
        </div>

        <div class="col-sm-3"> 
            <!-- Warning Alert Modal -->
            <div class="card   ">
                <div class="modal-body text-center p-4">
                    <span class="material-icons  ft-50  text-warning mb-2">warning</span>
                    <h4>Warning!</h4>
                    <p class="mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt eaque explicabo, itaque iusto unde quas magni non, quae expedita eveniet, incidunt molestiae tempora maiores odit hic perspiciatis. Eveniet, porro illo.</p>
                    <button type="button" class="btn btn-warning my-2" data-dismiss="modal">Continue</button>
                </div> <!-- // END .modal-body -->
            </div> <!-- // END .modal-content -->
        </div>

        <div class="col-sm-3"> 
                <!-- Danger Alert Modal -->
                <div class="card   bg-danger">
                    <div class="modal-body text-center p-4">
                        <span class="material-icons  ft-50 text-white mb-2">report_problem</span>
                        <h4 class="text-white">Ohhh Snap!</h4>
                        <p class="text-white mt-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sunt eaque explicabo, itaque iusto unde quas magni non, quae expedita eveniet, incidunt molestiae tempora maiores odit hic perspiciatis. Eveniet, porro illo.</p>
                        <button type="button" class="btn btn-light my-2" data-dismiss="modal">Continue</button>
                    </div> <!-- // END .modal-body -->
                </div> <!-- // END .modal-content -->
        </div>
     
</div>
</div>

<div class="container-fluid page__container">
     
    <div class="row">
        <div class="col-lg">
            <div class="card">
                <div class="card-header card-header-large bg-white">
                    <h4 class="card-header__title">Alerts</h4>
                </div>
                <div class="card-body">
                    <p>Alerts are available for any length of text, as well as an optional dismiss button. For proper styling, use one of the eight contextual classes (i.e. <code>.alert-success</code>). For background color use class <code>.bg-*</code> and <code>.text-white</code>.</p>

                    <div class="alert alert-primary" role="alert">
                        <strong>Primary - </strong> A simple primary alert - check it out!
                    </div>
                    <div class="alert alert-secondary" role="alert">
                        <strong>Secondary - </strong> A simple secondary alert - check it out!
                    </div>
                    <div class="alert alert-success" role="alert">
                        <strong>Success - </strong> A simple success alert - check it out!
                    </div>
                    <div class="alert alert-danger" role="alert">
                        <strong>Danger - </strong> A simple danger alert - check it out!
                    </div>
                    <div class="alert alert-warning" role="alert">
                        <strong>Warning - </strong> A simple warning alert - check it out!
                    </div>
                    <div class="alert alert-info" role="alert">
                        <strong>Info - </strong> A simple info alert - check it out!
                    </div>
                    <div class="alert alert-light" role="alert">
                        <strong>Light - </strong> A simple light alert - check it out!
                    </div>
                    <div class="alert alert-dark" role="alert">
                        <strong>Dark - </strong> A simple dark alert - check it out!
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg">
            <div class="card">
                <div class="card-header card-header-large bg-white">
                    <h4 class="card-header__title">Dismissible Alerts</h4>
                </div>
                <div class="card-body">
                    <p>Add a button and the <code>.alert-dismissible</code> class, which adds extra padding to the right of the alert and positions the <code>.close</code> button.</p>

                    <div class="alert alert-dismissible bg-primary text-white border-0 fade show" role="alert">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <strong>Primary - </strong> A simple primary alert - check it out!
                    </div>

                    <div class="alert alert-dismissible bg-secondary text-white border-0 fade show" role="alert">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <strong>Secondary - </strong> A simple secondary alert - check it out!
                    </div>

                    <div class="alert alert-dismissible bg-success text-white border-0 fade show" role="alert">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <strong>Success - </strong> A simple success alert - check it out!
                    </div>

                    <div class="alert alert-dismissible bg-danger text-white border-0 fade show" role="alert">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <strong>Danger - </strong> A simple danger alert - check it out!
                    </div>

                    <div class="alert alert-dismissible bg-warning border-0 fade show" role="alert">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <strong>Warning - </strong> A simple warning alert - check it out!
                    </div>

                    <div class="alert alert-dismissible bg-info text-white border-0 fade show" role="alert">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <strong>Info - </strong> A simple info alert - check it out!
                    </div>

                    <div class="alert alert-dismissible bg-light border-0 fade show" role="alert">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <strong>Light - </strong> A simple light alert - check it out!
                    </div>

                    <div class="alert alert-dismissible bg-dark text-white border-0 fade show" role="alert">
                        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <strong>Dark - </strong> A simple dark alert - check it out!
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card">
        <div class="card-header card-header-large bg-white">
            <h4 class="card-header__title">Soft Alerts</h4>
        </div>
        <div class="card-body">
            <p>Alternate styles for alerts with softer background color by replacing classes like <code>.alert-primary</code> with <code>.alert-soft-primary</code>. Alerts can also contain additional HTML elements like icons and paragraphs.</p>

            <div class="row">
                <div class="col-lg">
                    <div class="alert alert-soft-primary d-flex align-items-center" role="alert">
                        <i class="material-icons mr-3">flag</i>
                        <div class="text-body"><strong>Tip:</strong> Holding <code>Shift</code> and pressing <code>Enter</code> will create a new line in your message without posting it in channel. Learn more about <a href="">formatting your messages</a>.</div>
                    </div>

                    <div class="alert alert-soft-success d-flex  align-items-center" role="alert">
                        <i class="material-icons mr-3">edit</i>
                        <div class="text-body"><strong>Note:</strong> If you have two-factor authentication turned on, you will need to enter the 6-digit authentication code we send to your phone.</div>
                    </div>

                    <div class="alert alert-soft-warning d-flex  align-items-center m-0" role="alert">
                        <i class="material-icons mr-3">error_outline</i>
                        <div class="text-body"><strong>PRO and FREE versions are now available.</strong> Stack Admin is the easiest way to build or style your dashboard/application. Please visit our <a href="">product page</a> for more information.</div>
                    </div>
                </div>
                <div class="col-lg">
                    <div class="alert alert-soft-secondary d-flex align-items-center" role="alert">
                        <i class="material-icons mr-3">adjust</i>
                        <div class="text-body"><strong>Secondary - </strong> A simple secondary alert - check it out!.</div>
                    </div>
                    <div class="alert alert-soft-danger d-flex align-items-center" role="alert">
                        <i class="material-icons mr-3">error_outline</i>
                        <div class="text-body"><strong>Danger - </strong> A simple danger alert - check it out!.</div>
                    </div>
                    <div class="alert alert-soft-info d-flex align-items-center" role="alert">
                        <i class="material-icons mr-3">info_outline</i>
                        <div class="text-body"><strong>Info - </strong> A simple info alert - check it out!.</div>
                    </div>
                    <div class="alert alert-soft-dark d-flex align-items-center" role="alert">
                        <i class="material-icons mr-3">info</i>
                        <div class="text-body"><strong>Dark - </strong> A simple dark alert - check it out!.</div>
                    </div>
                    <div class="alert alert-soft-light d-flex align-items-center" role="alert">
                        <i class="material-icons mr-3">access_time</i>
                        <div class="text-body"><strong>Light - </strong> A simple light alert - check it out!.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-header card-header-large bg-white">
                    <h4 class="card-header__title">Badges Regular</h4>
                </div>
                <div class="card-body">
                    <p>A small count and labeling component. Please read the <a href="https://getbootstrap.com/docs/4.1/components/badge/" target="_blank">official Bootstrap documentation</a> for a full list of options.</p>

                    <span class="badge badge-primary">Primary</span>
                    <span class="badge badge-secondary">Secondary</span>
                    <span class="badge badge-success">Success</span>
                    <span class="badge badge-danger">Danger</span>
                    <span class="badge badge-warning">Warning</span>
                    <span class="badge badge-info">Info</span>
                    <span class="badge badge-light">Light</span>
                    <span class="badge badge-dark">Dark</span><br><br>
                    <span class="badge badge-pill badge-primary">Primary</span>
                    <span class="badge badge-pill badge-secondary">Secondary</span>
                    <span class="badge badge-pill badge-success">Success</span>
                    <span class="badge badge-pill badge-danger">Danger</span>
                    <span class="badge badge-pill badge-warning">Warning</span>
                    <span class="badge badge-pill badge-info">Info</span>
                    <span class="badge badge-pill badge-light">Light</span>
                    <span class="badge badge-pill badge-dark">Dark</span>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-header card-header-large bg-white">
                    <h4 class="card-header__title">Badges Soft</h4>
                </div>
                <div class="card-body">
                    <p>A variation of inversed badges color.</p>

                    <span class="badge badge-soft-primary">Primary</span>
                    <span class="badge badge-soft-secondary">Secondary</span>
                    <span class="badge badge-soft-success">Success</span>
                    <span class="badge badge-soft-danger">Danger</span>
                    <span class="badge badge-soft-warning">Warning</span>
                    <span class="badge badge-soft-info">Info</span>
                    <span class="badge badge-soft-light">Light</span>
                    <span class="badge badge-soft-dark">Dark</span><br><br>
                    <span class="badge badge-pill badge-soft-primary">Primary</span>
                    <span class="badge badge-pill badge-soft-secondary">Secondary</span>
                    <span class="badge badge-pill badge-soft-success">Success</span>
                    <span class="badge badge-pill badge-soft-danger">Danger</span>
                    <span class="badge badge-pill badge-soft-warning">Warning</span>
                    <span class="badge badge-pill badge-soft-info">Info</span>
                    <span class="badge badge-pill badge-soft-light">Light</span>
                    <span class="badge badge-pill badge-soft-dark">Dark</span>
                </div>
            </div>
        </div>
    </div>
</div>

 