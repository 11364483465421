import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {



    displayedColumns: string[] = ['id', 'name', 'progress', 'color'];
    dataSource: MatTableDataSource<any>;
  
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
  
    constructor() {

      let users = [];
      users = [{id: 1,name:'Asd',progress: 29,color: 'blue'},
      {id: 2,name:'Bgh',progress: 29,color: 'blue'},
      {id: 3,name:'Rdg',progress: 29,color: 'blue'},
      {id: 4,name:'POk',progress: 29,color: 'blue'},
      {id: 5,name:'ihj',progress: 29,color: 'blue'},
      {id: 3,name:'Rdg',progress: 29,color: 'blue'},
      {id: 4,name:'POk',progress: 29,color: 'blue'},
      {id: 5,name:'ihj',progress: 29,color: 'blue'}]
  
      // Assign the data to the data source for the table to render
      this.dataSource = new MatTableDataSource(users);
    }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }



}
